(function () {
  'use strict';

  class User {
    constructor(UserRest, $cookies, $rootScope) {
      this.UserRest = UserRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    login(user, callback = angular.noop) {
      const main = this;
      return this.UserRest.login().save(user,
        function (data) {
          main.setUserCookie(data);
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    usersIndex(user, callback = angular.noop) {
      return this.UserRest.user().get(user,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    myStudents(user, callback = angular.noop) {
      return this.UserRest.myStudents().get(user,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    signup(user, validating = false, callback = angular.noop) {
      const main = this;
      return this.UserRest.users().save(user,
        function (data) {
          if (!validating) {
            main.setUserCookie(data);
          }
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    getStudents(user, callback = angular.noop) {
      return this.UserRest.students().get(user,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    getTeachers(user, callback = angular.noop) {
      return this.UserRest.teachers().get(user,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    createTeacher(teacher, callback = angular.noop) {
      return this.UserRest.teachers().save({teacher: teacher},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    editTeacher(teacher, callback = angular.noop) {
      return this.UserRest.teachers().update({teacher: teacher},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    update(user, validating = false, callback = angular.noop) {
      const main = this;
      return this.UserRest.user().update({user: user},
        function (data) {
          if (!validating) {
            main.setUserCookie(data);
          }
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    getAdmins(user, callback = angular.noop) {
      return this.UserRest.administrators().get(user,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
    createAdmin(admin, callback = angular.noop) {
      return this.UserRest.administrators().save({user: admin},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    userView(user = {id: this.$rootScope.user.id}, callback = angular.noop) {
      return this.UserRest.user().get(user,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    userEdit(user = {id: this.$rootScope.user.id}, login = false, callback = angular.noop) {
      let main = this;
      return this.UserRest.user().update(user,
        function (data) {
          if (login) {
            main.setUserCookie(data);
          }
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    userDelete(user = {}, callback = angular.noop) {
      return this.UserRest.user().remove(user,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    profile(user = {id: this.$rootScope.user.id}, callback = angular.noop) {
      return this.UserRest.profile().get(user,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    studentProgress(user = {id: this.$rootScope.user.id}, callback = angular.noop) {
      return this.UserRest.studentProgress().get(user,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    userReviewsIndex(review = {}, callback = angular.noop) {
      return this.UserRest.userReviews().get(review,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    userReviewsCreate(review = {}, callback = angular.noop) {
      return this.UserRest.userReviews().save(review,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    invitationsIndex(invitation = {}, callback = angular.noop) {
      return this.UserRest.invitations().get(invitation,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    invitationsCreate(invitation = {}, callback = angular.noop) {
      return this.UserRest.invitations().save(invitation,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    passwordRecovery(invitation = {}, callback = angular.noop) {
      invitation.passwordRecovery = 'password_recovery';
      return this.UserRest.users().save(invitation,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    importUsers(importUsers = {}, callback = angular.noop) {
      return this.UserRest.importUsers().save(User.setFile(importUsers),
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    static setFile(file) {
      var fd = new FormData();
      fd.append('file', file, file.name || 'info.csv');
      return fd;
    }

    logout() {
      this.deleteUserCookie();
    }
    setUserCookie(user) {
      let schoolName = 'Not associated';
      if (user.user.role === 'admin' || user.user.role === 'superadmin') {
        delete user.user.groups;
      } else if (user.user.role === 'Teacher') {
        schoolName = user.user.school.name;
      } else {
        schoolName = user.user.classroom.school.name;
      }
      this.$cookies.putObject('user', {
        user: {
          id: user.user.id,
          token: user.user.token,
          classroom_id: user.user.classroom && user.user.classroom.id || null,
          group_id: user.user.group && user.user.group.id || null,
          group_title: user.user.group && user.user.group.title || null,
          school_id: user.user.school_id,
          first_name: user.user.first_name,
          last_name: user.user.last_name,
          full_name: user.user.full_name,
          role: user.user.role,
          school_name: schoolName
        }
      });
      this.setRootScopeUser();
    }
    deleteUserCookie() {
      this.$cookies.remove('user');
      delete this.$rootScope.user;
    }
    getUserCookie() {
      return this.$cookies.getObject('user');
    }
    setRootScopeUser() {
      let user = this.getUserCookie();
      if (user && user.user) {
        this.$rootScope.user = user.user;
      }
    }
  }

  /**
   * @ngdoc service
   * @name components.service:User
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('User', User);
}());
